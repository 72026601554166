var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageTop1'),_c('PageTop'),_c('div',{staticClass:"details v2-g-w1200"},[_c('div',{staticClass:"navList-l"},[_vm._m(0),_c('div',{staticClass:"xian"}),_vm._l((_vm.navList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"nav-name"},[_c('img',{staticClass:"img",attrs:{"src":item.img,"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"m-list-info"},_vm._l((item.row),function(car,index){return _c('div',{key:index,class:[
                        {
                            active: car.name === _vm.curName

                        },
                        'm-car-item'
                    ]},[_c('div',{staticClass:"m-car-name",on:{"click":function($event){return _vm.toLink(car)}}},[_vm._v(" "+_vm._s(car.title)+" ")])])}),0)])})],2),_c('div',{staticClass:"navList-r"},[_c('router-view')],1)]),_c('PageBottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ListTab"},[_c('div',{staticClass:"ListTabImg"},[_c('img',{attrs:{"src":require("./images/gr_gerenzhongxin.png"),"alt":""}})]),_c('div',{staticClass:"nav-title"},[_vm._v("个人中心")])])
}]

export { render, staticRenderFns }