<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>
        <div class="details v2-g-w1200">
            <div class="navList-l">
                <div class="ListTab">
                    <div class="ListTabImg"><img src="./images/gr_gerenzhongxin.png" alt=""></div>
                    <div class="nav-title">个人中心</div>
                </div>
                <div class="xian"></div>
                <div v-for="(item, index) in navList" :key="index">
                    <div class="nav-name">
                        <img :src="item.img" alt="" class="img" />
                        {{ item.title }}
                    </div>
                    <div class="m-list-info">
                        <div v-for="(car, index) in item.row" :key="index" :class="[
                            {
                                active: car.name === curName

                            },
                            'm-car-item'
                        ]">
                            <div class="m-car-name" @click="toLink(car)">
                                {{ car.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navList-r">
                <!-- 详情显示 -->
                <router-view></router-view>
            </div>
        </div>
        <PageBottom></PageBottom>
    </div>
</template>

<script>
import PageTop1 from '../../components-v2/layout/page-top1.vue';
import PageTop from '../../components-v2/layout/page-top.vue';
import { AccountStorage } from "@services/account";
import PageBottom from '../../components-v2/layout/page-bottom.vue';
const accountStorage = new AccountStorage();

export default {
    name: 'personalDetails',
    data() {
        return {
            isExternal: false,
            curName: null,
            navList: [
                {
                    title: "个人信息",
                    img: require("./images/geren.png"),
                    row: [
                        {
                            title: "基本信息",
                            name: 'v2-information'
                        },
                        {
                            title: "我的账户",
                            name: 'v2-myaccount'
                        },
                        {
                            title: "账号安全",
                            name: 'v2-security'
                        },
                        // {
                        //     title: "收货地址",
                        //     name: 'v2-address'
                        // },
                    ]
                },
                {
                    id: 1,
                    title: "业务管理",
                    img: require("./images/yewu.png"),
                    row: [
                        {
                            title: "我的订单",
                            name: 'v2-order'
                        },
                        {
                            title: "我的账单",
                            name: 'v2-reconciliation'
                        },
                        {
                            title: "报计划",
                            name: 'v2-planlist'
                        },
                        {
                            title: "我的收藏",
                            name: 'v2-collection'
                        },
                        {
                            title: "商品建议",
                            name: 'v2-buy'
                        },
                    ]
                },
                // {
                //   name: "我的账单",
                //   img: require("./images/order.png"),
                //   row: [
                //     {
                //       name: "全部账单",
                //       path: "/user-center/bill/list/0"
                //     }
                //   ]
                // },
                {
                    id: 2,
                    title: "发票管理",
                    img: require("./images/fapiao.png"),
                    row: [
                        {
                            title: "发票抬头",
                            name: 'v2-invoicetop'

                        },

                        {
                            title: "发票中心",
                            name: 'v2-electronicInvoiceList'

                        },
                    ]
                },
                {
                    id: 3,
                    title: "资料管理",
                    img: require("./images/ziliao.png"),
                    row: [
                        {
                            title: "药品资料下载",
                            name: 'v2-download'

                        },
                        {
                            title: "药品检验报告下载",
                            name: 'v2-drugtest'

                        },
                    ]
                },
                {
                    id: 4,
                    title: "售后管理",
                    img: require("./images/shouhou.png"),
                    row: [
                        {
                            title: "投诉建议",
                            name: 'v2-complaint'

                        }

                    ]
                },
                {
                    title: "优惠劵",
                    img: require("./images/xiaoxi.png"),
                    row: [
                        {
                            title: "全部优惠劵",
                            name: 'v2-coupon'

                        }
                    ]
                },
                {
                    title: "消息中心",
                    img: require("./images/xiaoxi.png"),
                    row: [
                        {
                            title: "消息中心",
                            name: 'v2-news'

                        },
                    ]
                },

            ]
        }
    },
    created() {
        let userInfo = accountStorage.getCacheUserInfo();
        if (userInfo) {
            this.isExternal = userInfo.type == 1 ? true : false;
        }
        this.curName = this.$route.name;
    },
    components: { PageTop, PageTop1, PageBottom },

    watch: {
        // 监听vue-router的路由变化
        $route(newRoute) {
            this.curName = newRoute.name
        }
    },
    methods: {
        toLink(car) {
            //限制潜客信息
            if (
                car.name != "v2-information" &&
                car.name != "v2-myaccount" &&

                this.isExternal
            ) {
                this.$messageTip("您尚未成为正式会员，无法进入该页面", 'error');
                return;
            }
            this.curName = car.name;

            if (this.curName === undefined) {
                return false;
            }

            if (this.curName == 'v2-order') {
                this.$router.push({
                    name: `${this.curName}`,
                    params: { status: '0' }
                });
                return
            }

            this.$router.push({
                name: `${this.curName}`,
            });
        }
    }
}
</script>

<style lang="less" scoped>
// 左侧导航栏
.details {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;

    .navList-l {
        width: 210px;
        background: #FCFCFC;
        border-radius: 10px;

        .xian {
            width: 201px;
            height: 1px;
            background: #E8EFF6;
        }

        .ListTab {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 24px;
            margin-bottom: 20px;

            img {
                border: 1px solid @shop-color-theme;
                vertical-align: middle;
                width: 28px;
                height: 28px;
                border-radius: 5px;
            }
        }

        .nav-title {
            padding-left: 12px;
            height: 40px;
            letter-spacing: 1px;
            font-weight: 500;
            line-height: 40px;
            font-size: 1.13rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
        }

        .nav-name {
            height: 40px;
            padding-left: 20px;
            font-weight: 400;
            letter-spacing: 1px;
            color: #333333;
            display: flex;
            align-items: center;
            font-size: 1rem;
            font-family: Source Han Sans CN;
            font-weight: bold;

            .img {
                width: 16px;
                height: 16px;
                margin-right: 7px;
            }
        }

        .m-list-info {
            .m-car-item {
                height: 40px;
                color: #666666;
                margin-bottom: 7px;

                .m-car-name {
                    text-align: left;
                    padding-left: 45px;
                    line-height: 40px;
                    letter-spacing: 1px;
                    font-weight: 500;
                    // padding: 20px 0;
                    font-size: .88rem;
                    font-family: Source Han Sans CN;
                    color: #686E70;

                    &:hover {
                        color: #fff !important;
                        cursor: pointer;
                        background: @shop-color-theme;
                    }
                }

                &.active {
                    background: @shop-color-theme;

                    .m-car-name {
                        color: #fff;

                    }
                }
            }

        }
    }

    .navList-r {
        flex: 1;
        padding-left: 20px;
    }
}
</style>
